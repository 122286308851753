import React from "react"
import { useEffect } from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"

// Components
import { Link } from "gatsby"
import Logo from "../images/seelay-art.inline.svg"
import Layout from "../components/layout"
import SEO from "../components/seo"
import PageHeader from "../components/pageheader"
import Container from "../components/container"
import Article from "../components/article"

const PrivacyPage = ({ data }) => {
  const privacy = data.allMarkdownRemark.edges[0].node

  useEffect(() => {
    window.addEventListener(
      "contextmenu",
      function(e) {
        // do something here...
        e.preventDefault()
      },
      false
    )
  }, [])

  return (
    <Layout theme="dark">
      <SEO
        title="Privacy | SeelayArt 2.0"
        description={privacy.frontmatter.description}
      />
      <PageHeader style={{ justifyContent: "center" }}>
        <h1
          id="bigtitle"
          className="page-header__headline  page-header__headline--text-center"
        >
          {privacy.frontmatter.title}
        </h1>
        <p className="page-header__desc">{privacy.frontmatter.description}</p>
      </PageHeader>
      <Container>
        <Article>
          <div dangerouslySetInnerHTML={{ __html: privacy.html }}></div>
        </Article>
      </Container>
      <Container id="footer">
        <footer className="footer">
          <Link to="/#">
            <Logo />
          </Link>
          <div className="footer__notes">
            <span className="footer__item">© 2024</span>
            <a target="blank" href="https://www.seelay.in">
              <span id="seelay-footer">SEELAY</span>
            </a>
            <Link to="/legal" className="footer__item">
              Legal
            </Link>
          </div>
        </footer>
      </Container>
    </Layout>
  )
}

export const pageQuery = graphql`
  query privacyPage {
    allMarkdownRemark(      
      filter: {fileAbsolutePath: {regex: "/(\/content\/pages\/privacy)/.*\\.md$/"}}
    ) {
      edges {
        node {
          id
          html
          frontmatter {
            title
            description
          }
        }
      }
    }
  }
`

PrivacyPage.propTypes = {
  data: PropTypes.object,
}

export default PrivacyPage
